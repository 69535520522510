// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLoaderData, useLocation, useRouteError } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { useState } from 'react';
import { cssBundleHref } from '@remix-run/css-bundle';
import { ErrorMessage } from '~ui/Messages/ErrorMessage';
import { getTimezone, setTimezone } from '~common/components/alert/server/default-session.server';
import { activeChannel } from '~kiosk/providers/channel/channel';
import { APP_META_DESCRIPTION, APP_META_TITLE } from '~kiosk/constants';
import { getCollections } from '~kiosk/providers/collections/collections';
import { getChannelToken, setApiUrl } from '~kiosk/graphqlWrapper';
import { Version } from '~common/components/Version';
import { Header } from './components/header';
import styles from './styles/app.css';
import { sessionStorage, channelTokenKey } from './sessions.server';
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  console.log('Error boundary reached for root:' + JSON.stringify(error));

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return <html lang="en" id="app">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <title>MAOS Supported Application</title>
          <Links />
        </head>
        <body>
          <main className="h-screen flex items-center justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <ErrorMessage heading="We are unable to connect at the moment" message="
                        We could not connect due to a technical issue on our
                        end. Please try again shortly.
                        If the issue keeps happening, contact customer care
                        at 262-505-6555." />





              <p>Status: {error.status}</p>
              <p>{error.data.message}</p>
            </div>
          </main>
          <Scripts />
        </body>
      </html>;
  } else {
    if (error instanceof Error) {
      console.log(`General error: ${error.message}`);
    } else {
      console.log('Not a routing error:' + JSON.stringify(error));
    }
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  const errorMessage = 'Unknown error';
  // if (isDefinitelyAnError(error)) {
  //   errorMessage = error.message;
  // }

  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>MAOS Supported Application</title>
        <Links />
      </head>
      <body>
        <main className="h-screen flex items-center justify-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <ErrorMessage heading="We are unable to connect at the moment" message="
                        We could not connect due to a technical issue on our
                        end. Please try again shortly.
                        If the issue keeps happening, contact customer care
                        at 262-505-6555." />





          </div>
        </main>
        <Scripts />
      </body>
    </html>;
}
_s(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: styles
}, {
  rel: 'stylesheet',
  href: cssBundleHref
}] : [])
// ...
];
export const meta = ({
  data
}) => {
  const metaTitle = data?.activeChannel.customFields?.metaTitle || data?.activeChannel.customFields?.storeTitle || APP_META_TITLE;
  const metaDescription = data?.activeChannel.customFields?.metaDescription || data?.activeChannel.customFields?.storeDescription || APP_META_DESCRIPTION;
  return [{
    title: metaTitle
  }, {
    name: 'description',
    content: metaDescription
  }];
};
const devMode = typeof process !== 'undefined' && process.env.NODE_ENV === 'development';
export async function loader({
  request,
  params,
  context
}) {
  const ENV = {
    DISABLE_SENTRY: process.env?.DISABLE_SENTRY,
    SENTRY_DSN: process.env?.SENTRY_DSN,
    VENDURE_API_URL: process.env?.VENDURE_API_URL,
    VERSION: process.env?.METRIX_VERSION ?? '0.0.0'
  };
  if (typeof context.VENDURE_API_URL === 'string') {
    // Set the API URL for Cloudflare Pages
    setApiUrl(context.VENDURE_API_URL);
  }
  const collectionData = await getCollections(undefined, {
    request
  });
  const collections = collectionData.collections.items;
  const topLevelCollections = collections.filter(collection => collection.parent?.name === '__root_collection__');
  const headers = new Headers();
  const channel = await activeChannel({
    request
  });
  const session = await getTimezone(request);
  if (session.get('timezone') !== channel?.customFields?.timezone) {
    const ck = await setTimezone(request, channel?.customFields?.timezone ?? '');
    headers.append('Set-Cookie', ck);
  }
  const channelToken = await getChannelToken(request, undefined, true);
  const rmxSession = await sessionStorage.getSession(request?.headers.get('Cookie'));
  if (rmxSession.get(channelTokenKey) !== channelToken) {
    rmxSession.set(channelTokenKey, channelToken);
    const ck = await sessionStorage.commitSession(rmxSession);
    headers.append('Set-Cookie', ck);
  }
  const loaderData = {
    activeChannel: channel,
    collections: topLevelCollections,
    ENV
  };
  return json(loaderData, {
    headers
  });
}
export default function App() {
  _s2();
  const [open, setOpen] = useState(false);
  const loaderData = useLoaderData();
  const {
    collections,
    activeChannel,
    ENV
  } = loaderData;
  const metaTitle = activeChannel.customFields?.metaTitle;
  const metaDescription = activeChannel.customFields?.metaDescription;
  const favIcon = activeChannel.customFields?.favIcon?.preview;
  const [isSignedIn, setIsSignedIn] = useState(false);
  const location = useLocation();
  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href={favIcon} type="image/png"></link>
        <Meta />
        <Links />
      </head>
      <body>
        <Header />
        <main className="pb-24 h-full">
          <Outlet context={{
          activeChannel
        }} />

        </main>

        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(ENV)}`
      }} />

        <Scripts />
        {devMode && <LiveReload />}
        <Version version={ENV.VERSION} hide />
      </body>
    </html>;
}
_s2(App, "Y50bdYDMXEezIZX0Eo0ulcJyk0U=", false, function () {
  return [useLoaderData, useLocation];
});
_c2 = App;
function isDefinitelyAnError(error) {
  throw new Error('Function not implemented.');
}
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;